<template>
  <div>
    <b-modal
      v-model="showModalCalcul"
      ok-only
      ok-title="Accept"
      centered
      size="sm"
      :hide-footer="true"
      title="Nouveau code promo"
      lazy
      static
    >
      <p>Sélectionne le pays: </p>
      <v-select v-model="countrySelect" label="label" :options="[{label: 'LU', value: 1.17}, {label: 'BE', value: 1.21}, {label: 'CH', value: 1}, {label: 'AT', value: 1.2}]"
      transition=""  />
      <b-button @click="convertForCountrie()" variant="primary" class="mt-1">Convertir</b-button>
    </b-modal>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Nouveau code promo"
      lazy
      static
    >
      <b-row class="my-1">
        <p>Tous les champs avec un asterix (*) sont obligatoires.</p>
        <validation-observer
          ref="refFormObserver"
        >
          <b-form @submit.prevent="submit">
            <b-row>
              <b-col md="6">
                <b-form-group label="Code *">
                  <validation-provider
                    name="Code"
                    rules="required"
                  >
                    <b-form-input
                      v-model="newCoupon.code"
                      placeholder="Code"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Description *">
                  <validation-provider
                    name="Description"
                    rules="required"
                  >
                    <b-form-input
                      v-model="newCoupon.description"
                      placeholder="Description"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Type *">
                  <validation-provider
                    name="Type"
                    rules="required"
                  >
                    <v-select
                      v-model="newCoupon.discount_type"
                      placeholder="Type de coupon"
                      label="name"
                      :options="types"
                      :reduce="discount => discount.type"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Montant *">
                  <validation-provider
                    name="Montant"
                    rules="required"
                  >
                    <b-form-input
                      v-model="newCoupon.amount"
                      type="integer"
                      placeholder="Montant"
                    />
                  </validation-provider>
                  <b-button @click="showModalCalcul = true" variant="primary" class="mt-1">Convertir pour un autre pays (BE,LU,CH,etc...)</b-button>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Date d'expiration *">
                  <validation-provider
                    name="Date d'expiration"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="newCoupon.date_expires"
                      placeholder="Choisissez une date"
                      class="form-control mr-1"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Utilisation individuelle">
                  <validation-provider
                    name="Type"
                  >
                    <v-select
                      v-model="newCoupon.individual_use"
                      placeholder="Utilisation individuelle"
                      label="name"
                      :options="individualType"
                      :reduce="individual => individual.type"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Produits obligatoires">
                  <validation-provider
                    name="Produits obligatoires"
                  >
                    <v-select
                      v-model="newCoupon.product_ids"
                      placeholder="Produit"
                      label="name"
                      :options="products"
                      :reduce="product => product.idWoo"
                      :clearable="false"
                      :multiple="true"
                      class="invoice-filter-select"
                      transition=""
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="6">
                <b-form-group label="Produits exclus">
                  <validation-provider
                    name="Produits exclus"
                    rules="required"
                  >
                    <v-select
                      v-model="newCoupon.excluded_product_ids"
                      placeholder="Produit"
                      label="name"
                      :options="products"
                      :reduce="product => product.id"
                      :clearable="false"
                      :multiple="true"
                      class="invoice-filter-select"
                      transition=""
                    />
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col md="6">
                <b-form-group label="Type de condition">
                  <validation-provider
                    name="Type de condition"
                  >
                    <v-select
                      v-model="conditionalProducts"
                      placeholder="Type de condition"
                      label="name"
                      :options="conditions"
                      :reduce="condition => condition.type"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Catégories obligatoires">
                  <validation-provider
                    name="Catégories obligatoires"
                  >
                    <v-select
                      v-model="newCoupon.product_categories"
                      placeholder="Catégorie"
                      label="title"
                      :options="categories"
                      :reduce="category => category.id"
                      :clearable="false"
                      :multiple="true"
                      class="invoice-filter-select"
                      transition=""
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- <b-col md="6">
                <b-form-group label="Catégories exclues">
                  <validation-provider
                    name="Catégories exclues"
                    rules="required"
                  >
                    <v-select
                      v-model="newCoupon.excluded_product_categories"
                      placeholder="Catégorie"
                      label="title"
                      :options="categories"
                      :reduce="category => category.id"
                      :clearable="false"
                      :multiple="true"
                      class="invoice-filter-select"
                      transition=""
                    />
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <b-col md="6">
                <b-form-group label="Type de condition">
                  <validation-provider
                    name="Type de condition"
                  >
                    <v-select
                      v-model="conditionalCategories"
                      placeholder="Type de condition"
                      label="name"
                      :options="conditions"
                      :reduce="condition => condition.type"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Montant minimum">
                  <validation-provider
                    name="Montant minimum"
                  >
                    <b-form-input
                      v-model="newCoupon.minimum_amount"
                      placeholder="Montant minimum"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Montant maximum">
                  <validation-provider
                    name="Montant maximum"
                  >
                    <b-form-input
                      v-model="newCoupon.maximum_amount"
                      placeholder="Montant maximum"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nombre d'utilisation maximum">
                  <validation-provider
                    name="Nombre d'utilisation maximum"
                  >
                    <b-form-input
                      v-model="newCoupon.data.maximum_used"
                      placeholder="Nombre d'utilisation maximum"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label="Nombre d'utilisation maximum par utilisateur *">
                  <validation-provider
                    name="Nombre d'utilisation maximum par utilisateur"
                    rules="required"
                  >
                    <b-form-input
                      v-model="newCoupon.data.maximum_used_by_user"
                      placeholder="Nombre d'utilisation maximum par utilisateur"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-row>
      <template>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            :disabled="loading"
            @click="createCoupon()"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Créer le code promo</span>
          </b-button>
        </b-row>
      </template>
      <!--<template v-else-if="coupon.shop">
        <b-row class="my-1">
          <b-col :md="2">
            <b-form-input v-model="coupon.shop.find(s => s.key === 'TEMPLE').value" type="number"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center justify-content-center text-center">
          <b-button
            variant="primary"
            class="mb-1"
            @click="updateValue(true)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span>Enregistrer le stock</span>
          </b-button>
        </b-row>
      </template>!-->
    </b-modal>
    <b-card>
      <b-row class="d-flex align-items-center">

        <!-- Per Page -->
        <b-col
          cols="3"
          md="3"
          class="d-flex align-items-center justify-content-start"
        >
          <v-select
            v-model="country"
            placeholder="Pays"
            label="Pays"
            :options="countryOptions"
            :clearable="false"
            class="invoice-filter-select mt-1"
          />
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="showModal = true"
            >
              <span class="text-nowrap">Créer un code promo</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card title="Vous pouvez rechercher les coupons directement ici.">
      <b-row>
        <b-col
          cols="12 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un coupon..."
                style="height: auto;"
                class="border-0 shadow-none"
                @keyup.enter="setFilter"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height">
        <b-table
          ref="selectableTable"
          selectable
          :items="coupons"
          :fields="fields"
          responsive="sm"
          @row-selected="onRowSelected"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>

          <template #row-details="data">
            <b-card>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong><u>Voir le code sur le BO:</u></strong> <a v-if="data.item._links && data.item._links.self && data.item._links.self.length" :href="`https://kipli.com/${country.toLowerCase()}/wp-admin/post.php?post=${data.item._links.self[0].href.split('/')[data.item._links.self[0].href.split('/').length - 1]}&action=edit`" > <feather-icon icon="ExternalLinkIcon" /> </a>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Crée le : </strong>{{ $moment(data.item.date_created).format('DD-MM-YYYY') }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Minimum achat: </strong>{{ `${data.item.minimum_amount === '0.00' ? 'Aucune limite' : `${data.item.minimum_amount || 0}€` }` }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Maximum achat: </strong>{{ `${data.item.maximum_amount === '0.00' ? 'Aucune limite' : `${data.item.maximum_amount || 0}€` }` }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Restric. Email : </strong>
                  <span
                    v-if="data.item.email_restrictions.length"
                    v-b-popover.hover.top="`${data.item.email_restrictions}`"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  >Liste</span>
                  <span v-else>Aucune</span>
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Clients : </strong>
                  <span
                    v-if="data.item.used_by && data.item.used_by.length"
                    v-b-popover.hover.top="`${data.item.used_by}`"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  >{{ data.item.used_by }}</span>
                  <div v-else-if="data.item.data && data.item.data.v2">
                    <span
                      v-for="(email, keyE) of data.item.data.used_emails"
                      :key="keyE"
                      v-b-popover.hover.top="`${email}`"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    ><a :href="`/commande?name=${email}`" target="_blank">{{ email }}</a></span>
                  </div>
                  <span v-else>Aucune</span>
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Limite par client : </strong>{{ data.item.data && data.item.data.v2 ? data.item.data.maximum_used_by_user : data.item.usage_limit_per_user }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Produits obligatoires : </strong>{{ data.item.product_ids.length ? data.item.product_ids.map(p => products.find(pr => pr.idWoo === p).name).join(' / ') : 'Aucun' }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Produits exclus : </strong>{{ data.item.excluded_product_ids.length ? data.item.excluded_product_ids.join(' / ') : 'Aucun' }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <span />
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Catégories obligat. : </strong>{{ data.item.product_categories.length ? data.item.product_categories.join(' / ') : 'Aucune' }}
                </b-col>
                <b-col
                  md="4"
                  class="mb-1"
                >
                  <strong>Catégories exclues : </strong>{{ data.item.excluded_product_categories.length ? data.item.excluded_product_categories.join(' / ') : 'Aucune' }}
                </b-col>
              </b-row>

              <b-button
                size="sm"
                variant="outline-secondary"
                @click="data.toggleDetails"
              >
                Cacher les détails
              </b-button>
            </b-card>
          </template>

          <template #cell(code)="data">
            <p class="font-weight-bolder">{{ data.item.code }}</p>
          </template>

          <template #cell(data)="data">
            <p class="font-weight-bolder">{{ data.item.data && data.item.data.isDelete ? 'Non' : 'Oui' }}</p>
          </template>

          <template #cell(usage_count)="data">
            <span v-if="data.item.usage_count">{{ data.item.usage_count }}</span>
            <b-progress
              v-else-if="data.item.usage_limit"
              v-b-popover.hover.top="`${data.item.usage_count}/${data.item.usage_limit}`"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              :value="Number(data.item.usage_count)"
              :max="Number(data.item.usage_limit)"
              :variant="getVariant(data.item)"
              striped
            />
            <span v-else-if="data.item.data && data.item.data.v2">{{ data.item.data.maximum_used }}</span>
            <span v-else>Illimité</span>
          </template>

          <template #cell(individual_use)="data">
            <b-badge :variant="`light-${data.item.individual_use ? 'success' : 'danger'}`">
              {{ data.item.individual_use ? 'Oui' : 'Non' }}
            </b-badge>
          </template>

          <template #cell(discount_type)="data">
            <b-badge :variant="`light-${data.item.discount_type === 'fixed_cart' ? 'info' : data.item.discount_type === 'percent' ? 'warning' : 'success'}`">
              {{ data.item.amount }}{{ data.item.discount_type === 'fixed_cart' ? '€' : data.item.discount_type === 'percent' ? '%' : data.item.discount_type }}
            </b-badge>
          </template>

          <template #cell(date_expires_gmt)="data">
            <b-badge :variant="`light-${$moment(data.item.date_expires_gmt).isBefore($moment()) ? 'danger' : 'success'}`">
              {{ data.item.date_expires_gmt ? $moment(data.item.date_expires_gmt).format('DD-MM-YYYY') : 'Aucune limite' }}
            </b-badge>
          </template>
          <!-- <template #cell(status)="data">
            {{ data }}
          </template> -->
        </b-table>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="itemsPerPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="getCoupons"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BModal,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BFormInput,
  BBadge,
  BTable,
  BProgress,
  BPagination,
  VBPopover,
  BFormCheckbox,
  BFormGroup,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    vSelect,
    BCard,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BButton,
    BProgress,
    BPagination,
    BFormCheckbox,
    BForm,
    BFormGroup,
    flatPickr,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseCoupons: [],
      coupons: [],
      products: [],
      categories: [],
      coupon: {},
      conditionalProducts: '',
      conditionalCategories: '',
      newCoupon: {
        amount: '',
        code: '',
        country: '',
        description: '',
        discount_type: '',
        email_restrictions: [],
        exclude_sale_items: false,
        excluded_product_categories: [],
        excluded_product_ids: [],
        free_shipping: false,
        individual_use: true,
        limit_usage_to_x_items: null,
        maximum_amount: null,
        meta_data: [],
        minimum_amount: null,
        product_categories: [],
        product_ids: [],
        data: {
          maximum_used: null,
          maximum_used_by_user: 1,
          used: 0,
          v2: true,
          used_emails: [],
        },
      },
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'show_details', label: 'Détails' }, 'code', { key: 'data', label: 'Fonctionnel' }, { key: 'description', label: 'Desc.' }, { key: 'usage_count', label: 'Utilisation' }, { key: 'individual_use', label: 'Util. Unique' }, { key: 'discount_type', label: 'Remise' }, { key: 'date_expires_gmt', label: 'Date exp.' }],
      showModal: false,
      showModalCalcul: false,
      countrySelect: null,
      categoryInformations: [],
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'IT', 'EN', 'NL', 'PT', 'DK', 'UK'],
      currentPage: 10,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 25,
      loading: false,
    }
  },
  watch: {
    country: {
      deep: true,
      handler: 'getCoupons',
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
  async mounted() {
    try {
      await this.getCoupons()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getCoupons(e) {
      try {
        this.currentPage = e
        const option = {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        }
        this.loading = true
        const filter = {
          country: this.country,
        }
        if (this.code !== '') {
          filter.code = { $regex: this.code, $options: 'i' }
        }
        const {
          coupons,
          totalPages,
          totalItems,
          itemsPerPage,
        } = await this.$http.get('/admin/coupons', { params: { ...option, filter } })
        this.coupons = coupons
        this.baseCoupons = coupons
        this.totalPages = totalPages
        this.totalItems = totalItems
        this.itemsPerPage = itemsPerPage
        const { products } = await this.$http.get(`/admin/blog/list-product/?country=${this.country}`)
        this.products = products
        const { categories } = await this.$http.get(`/admin/categories?country=${this.country}`)
        this.categories = categories
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async openModal(p) {
      console.log(p)
      const { coupon } = await this.$http.get(`/admin/coupons/${p._id}`)
      this.coupon = coupon.coupon
      this.coupon._id = coupon._id
      this.showModal = true
      console.log(coupon.coupon)
    },
    async updateValue() {
      try {
        await this.$http.put(`admin/coupons/${this.coupon._id}/stock`, { variations: this.coupon.variations })
        this.showModal = false
      } catch (err) {
        console.log(err)
      }
    },
    setFilter() {
      this.filter = { code: this.code, country: this.country }
      this.getCoupons(1)
    },
    filter(e) {
      this.filterQuery = e
      if (!e || e === '') {
        this.coupons = this.baseCoupons
      } else {
        const value = e
        this.coupons = this.baseCoupons.filter(coupon => coupon.code?.toLowerCase().includes(value.toLowerCase()))
      }
    },
    getVariant(item) {
      const variant = `${Number(item.usage_count) < Number(item.usage_limit) ? 'success' : 'danger'}`
      return variant
    },
    convertForCountrie() {
      let tvaBase = 1.2
      if (this.country === 'IT') {
        tvaBase = 1.22
      } else if (this.country === 'DE') {
        tvaBase = 1.19
      }
      // eslint-disable-next-line no-mixed-operators
      this.newCoupon.amount = (Number(this.newCoupon.amount) / this.countrySelect.value * (tvaBase)).toFixed(2)
      this.showModalCalcul = false
    },
    async createCoupon() {
      try {
        this.loading = true
        this.$refs.refFormObserver.validate().then(async success => {
          if (!success) {
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Vous devez renseigner tous les champs obligatoires.',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          } else {
            const meta_data = []
            if (this.conditionalProducts !== '') {
              meta_data.push({ key: '_wt_product_condition', value: this.conditionalProducts })
            }
            if (this.conditionalCategories !== '') {
              meta_data.push({ key: '_wt_category_condition', value: this.conditionalCategories })
            }
            this.newCoupon.meta_data = meta_data
            this.newCoupon.country = this.country
            await this.$http.post('admin/coupons', { coupon: this.newCoupon })
            this.showModal = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Votre code promo a bien été crééé.',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
